
/**
 * Object with role as key and value, which is used for 
 * comparison of role in different place.
 */
export const UserRoles = {
    role: "role"
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
    "role": "Role"
};

/**
 * Object which has the different themes used in 
 * the application.
 */
export let Themes = {
    default: "default",
    dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert) 
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
    vertical: {
        top: "top",
        bottom: "bottom",
    },
    horizontal: {
        left: "left",
        right: "right",
        center: "center",
    },
    severity: {
        success: "success",
        error: "error",
        warning: "warning",
        info: "info",
    },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js) 
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
    direction: {
        top: "top",
        bottom: "bottom",
        left: "left",
        right: "right",
    },
    variant: {
        permanent: "permanent",
        persistent: "persistent",
        temporary: "temporary",
    },
};

/**
 * Object has the key and value pair of all the keys which 
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
    authToken: "auth_token",
    version: "version",
    username: 'username',
    selected_country: "selected_country",
    current_location: "current_location",
    selected_country_detail: "selected_country_detail",
    client: "client",
    selected_currency: "selected_currency",
    residential_home_page: "residential_home_page",
    commercial_home_page: "commercial_home_page",
    facility_Home_Page: "facility_Home_Page",
    selected_metric: "selected_metric",
    user_Id: "user_Id",
};

// To build version string
export const VersionFetchURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/meta.json?v=${+new Date()}`;

export const borderRadius = "4px"

export const borderRadiusAlignments = {
    borderEight: "8px",
    borderSixteen: "16px",
    borderTwelve: "12px",
    border24: "24px"
}

// Calculation for the Px into Rem
export const remCalc = (val) => {
    return `${(val < 12 ? (12 / 16) : (val / 16))}rem`
}

// Calculation For Currency Change
export const currencyCalculation = (countryCurrencyValue, amount) => {
    return (countryCurrencyValue * amount).toFixed(2)
}


export const convertArea = (value, fromUnit, toUnit) => {
    const conversionFactors = {
        "Acre": {
            "Sq. Meter": 4046.86,
            "Sq. Feet": 43560,
            "Hectare": 0.404686,
            "Cent": 40468.6,
            "Sq. Yard": 4840,
            "Sq. Mile": 0.0015625,
            "Object": 1,
            "Acre": 1
        },
        "Sq. Meter": {
            "Acre": 0.000247105,
            "Sq. Feet": 10.7639,
            "Hectare": 0.0001,
            "Cent": 100,
            "Sq. Yard": 1.19599,
            "Sq. Mile": 3.861e-7,
            "Object": null,
            "Sq. Meter": 1
        },
        "Sq. Feet": {
            "Acre": 2.2957e-5,
            "Sq. Meter": 0.092903,
            "Hectare": 9.2903e-6,
            "Cent": 929.03,
            "Sq. Yard": 0.111111,
            "Sq. Mile": 3.587e-8,
            "Object": null,
            "Sq. Feet": 1
        },
        "Hectare": {
            "Acre": 2.47105,
            "Sq. Meter": 10000,
            "Sq. Feet": 107639,
            "Cent": 1000000,
            "Sq. Yard": 11959.9,
            "Sq. Mile": 0.003861,
            "Object": null,
            "Hectare": 1
        },
        "Cent": {
            "Acre": 2.47105e-8,
            "Sq. Meter": 0.01,
            "Sq. Feet": 0.107639,
            "Hectare": 1e-6,
            "Sq. Yard": 0.00119599,
            "Sq. Mile": 3.861e-12,
            "Object": null,
            "Cent": 1
        },
        "Sq. Yard": {
            "Acre": 0.000206612,
            "Sq. Meter": 0.836127,
            "Sq. Feet": 9,
            "Hectare": 8.3613e-5,
            "Cent": 8361.27,
            "Sq. Mile": 3.2283e-7,
            "Object": null,
            "Sq. Yard": 1
        },
        "Sq. Mile": {
            "Acre": 640,
            "Sq. Meter": 2.58999e+6,
            "Sq. Feet": 2.788e+7,
            "Hectare": 258.999,
            "Cent": 2.58999e+10,
            "Sq. Yard": 3.0976e+6,
            "Object": null,
            "Sq. Mile": 1
        },
        "Object": {

        }
    };

    // Check if the units are valid
    if (!conversionFactors[fromUnit] || !conversionFactors[toUnit]) {
        return value;
    }

    // Check if a conversion factor is defined
    if (conversionFactors[fromUnit][toUnit] === null) {
        return value;
    }
    // Perform the conversion
    const result = Number(value) * conversionFactors[fromUnit][toUnit];
    return Number(result);
}
